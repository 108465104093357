import React, {useEffect, useState} from "react";
import "./SubscriptionsPage.css";
import "../../../pageComponents/headerFooter/HeaderSection.css";
import Header from "../../../pageComponents/headerFooter/Header";
import Footer from "../../../pageComponents/headerFooter/Footer";
import {useNavigate} from "react-router-dom";
import Loader from "../../../loader/Loader";
import axios from "axios";
import Cookies from "universal-cookie";
import {useDispatch, useSelector} from "react-redux";
import {addUser, setLogin} from "../../../reduxStore/slices/GetUserSlice";

const ITEMS_PER_PAGE = 10;

const SubscriptionsPage = () => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const cookies = new Cookies();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);
    axios.defaults.withCredentials = true;

    useEffect(() => {
        const usatCookie = cookies.get("usat");
        if (!usatCookie && user?.sbStatus !== "admin") {
            navigate(`/login`);
        }
    }, [cookies, navigate, user?.sbStatus]);

    useEffect(() => {
        const fetchSubscriptions = async () => {
            setLoading(true);
            try {
                // Check if data exists in cache
                const cachedData = JSON.parse(localStorage.getItem("subscriptions"));
                const cacheTimestamp = localStorage.getItem("subscriptionsTimestamp");
                const now = new Date().getTime();

                if (cachedData && cacheTimestamp && now - cacheTimestamp < 10 * 60 * 1000) {
                    setSubscriptions(cachedData);
                    setLoading(false);
                    return;
                }

                if (cookies.get("usat")) {
                    const usat = document.cookie
                        .split('; ')
                        .find(row => row.startsWith('usat='))
                        ?.split('=')[1];

                    const response = await axios.post(`${process.env.REACT_APP_SERVER_DOMAIN}/subscriptions`, {
                        userId: user?.id || "Id Not set", username: user?.username || "User Not set"
                    }, {
                        headers: { Authorization: usat },
                        withCredentials: true,
                    });

                    const { success, data } = response.data;
                    if (success && Array.isArray(data)) {
                        setSubscriptions(data);
                        localStorage.setItem("subscriptions", JSON.stringify(data));
                        localStorage.setItem("subscriptionsTimestamp", now.toString());
                    } else {
                        console.error("Unexpected response format:", response.data);
                        setSubscriptions([]);
                    }
                } else {
                    dispatch(addUser(null));
                    dispatch(setLogin(false));
                    cookies.remove("usat");
                    cookies.remove("sbVersion");
                    navigate(`/login`);
                }
            } catch (error) {
                console.error("Error fetching subscriptions:", error);
                setError("Error fetching subscriptions");
                setSubscriptions([]);
            } finally {
                setLoading(false);
            }
        };

        if (user?.id) {
            fetchSubscriptions();
        }
        const intervalId = setInterval(() => {
            fetchSubscriptions();
        }, 10 * 60 * 1000);

        return () => clearInterval(intervalId);
    }, [cookies, navigate, user?.id, dispatch]);

    const totalPages = Math.ceil(subscriptions.length / ITEMS_PER_PAGE);
    const currentData = subscriptions.slice((currentPage - 1) * ITEMS_PER_PAGE, currentPage * ITEMS_PER_PAGE);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    return loading ? (
        <Loader/>
    ) : (
        <div className="subscriptions-page">
            <Header titleName="Subscriptions Information"/>
            <section className="subscriptions-content">
                {error ? (
                    <p className="error-message">{error}</p>
                ) : (
                    <>
                        <table className="subscriptions-table">
                            <thead>
                            <tr>
                                <th>Id</th>
                                <th>Username</th>
                                <th>Full Name</th>
                                <th>Profile Status</th>
                                <th>User Level</th>
                                <th>Subscription Status</th>
                                <th>Subscription ID</th>
                                <th>House Status</th>
                                <th>Package</th>
                                <th>Domain</th>
                            </tr>
                            </thead>
                            <tbody>
                            {currentData.map((subscription, index) => (
                                <tr key={index}>
                                    <td>{subscription.id}</td>
                                    <td>{subscription.username}</td>
                                    <td>{subscription.fullName}</td>
                                    <td>{subscription.profileStatus}</td>
                                    <td>{subscription.userLevel}</td>
                                    <td>{subscription.subscriptionStatus}</td>
                                    <td>{subscription.subscriptionId}</td>
                                    <td>{subscription.houseStatus}</td>
                                    <td>{subscription.housePackage}</td>
                                    <td>{subscription.houseDomain}</td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                        <div className="pagination">
                            {[...Array(totalPages)].map((_, index) => (
                                <button
                                    key={index}
                                    className={currentPage === index + 1 ? "active-page" : ""}
                                    onClick={() => handlePageChange(index + 1)}
                                >
                                    {index + 1}
                                </button>
                            ))}
                        </div>
                    </>
                )}
                <button
                    className="back-cabinet-btn"
                    onClick={() => navigate(`/cabinet/${user.username}/admin-page`)}
                >
                    Back to Admin Page
                </button>
            </section>
            <Footer/>
        </div>
    );
};

export default SubscriptionsPage;
