// PricingSection.jsx
import React from 'react';
import './Pricing.css';
import houseOne from "../../pageComponents/icons/svg/houses/House_1.svg";
import houseTwo from "../../pageComponents/icons/svg/houses/House_2.svg";
import houseThree from "../../pageComponents/icons/svg/houses/House_3.svg";
import houseFour from "../../pageComponents/icons/svg/houses/House_4.svg";

const description = <>
    Find more details<br/>
    About System & Installation<br/>
    here at <a className="card__description" href="/services/">services</a> page<br/>
    and <a className="card__description" href="/support/">support</a> page<br/>
    <br/>
</>;

const cardData = [
    { id: 1, title: 'Studio', price: '$415', description: description, icon: houseOne },
    { id: 2, title: '1-2 Bedroom', price: '$675', description: description, icon: houseTwo },
    { id: 3, title: '3-5 Bedroom', price: '$855', description: description, icon: houseThree },
    { id: 4, title: '5+ Bedroom', price: 'Price TBD', description: description, icon: houseFour },
];

const PricingSection = () => {
    return (
        <div className="pricing-section">
            {cardData.map(card => (
                <div key={card.id} className="card">
                    <div className="card__icon-container">
                        <img src={card.icon} alt="Logo" className="logo" style={{ width: '250px', height: 'auto' }} />
                        <p className="card__text">{card.title}</p>
                    </div>
                    <div className="card__content">
                        <p className="card__title">{card.price}</p>
                        <p className="card__description">{card.description}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default PricingSection;
