import React, {useState} from "react";
import Icon from '@mdi/react';
import { mdiMapMarker, mdiEmailOutline, mdiCellphoneSound } from '@mdi/js';
import { mdiFacebook, mdiYoutube, mdiInstagram, mdiTiktok } from '@mdi/js';
import "./FooterSection.css";  // Import Footer CSS
import tickTokSvg from "../../pageComponents/icons/svg/tiktok.svg";

import { useSelector, useDispatch } from "react-redux";
import { setLogin, addUser } from "../../reduxStore/slices/GetUserSlice";
import Cookies from "universal-cookie";
import {useNavigate} from "react-router-dom";
import { useHandleLogout } from "../../utils/LogoutUtils";

import logoSvg from "../icons/svg/Logo_SB_Full.svg";
const FooterSection = () => {
    const user = useSelector((state) => state.user.user); // Check if the user is logged in
    const dispatch = useDispatch();
    const cookies = new Cookies();
    const navigate = useNavigate();

    // Logout Function
    const handleLogout = useHandleLogout();

    const handleNavigate = () => {
        // Clear cookies or session tokens if necessary
        navigate(`/cabinet/${user.username}/home`);
    };

    return (
        <div className="footer">
            <footer>
                <div className="header">
                    <div className="waves-container-footer">
                        <svg className="waves footer-waves" xmlns="http://www.w3.org/2000/svg" viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                            <defs>
                                <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                            </defs>
                            <g className="parallax">
                                <use href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
                                <use href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                                <use href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                                <use href="#gentle-wave" x="48" y="7" fill="#fff" />
                            </g>
                        </svg>
                    </div>
                    <div className="top_header">
                        <section>
                            <Icon path={mdiMapMarker} size={1.5} color="currentColor" />
                            <span>Chicago, IL</span>
                        </section>
                        <section>
                            <Icon path={mdiCellphoneSound} size={1.5} color="currentColor" />
                            <span>(773) 236 9965</span>
                        </section>
                        <section>
                            <Icon path={mdiEmailOutline} size={1.5} color="currentColor" />
                            <span>SmartBirdService@gmail.com</span>
                        </section>
                    </div>
                    <div className="bottom_content">
                        <section className="socials">
                            <a className="aLink" href="https://www.facebook.com/people/Smart-Bird-Solution/61573005913888/"><Icon path={mdiFacebook} size={1} color="currentColor" /></a>
                            <a className="aLink" href="https://www.instagram.com/smartbirdsolution"><Icon path={mdiInstagram} size={1} color="currentColor" /></a>
                            <a className="aLink" href="https://www.youtube.com/@smartbirdsolution"><Icon path={mdiYoutube} size={1} color="currentColor" /></a>
                            <a className="aLink" href="https://www.tiktok.com/@smartbirdsolution"><Icon path="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3z" size={1} color="currentColor" style={{ verticalAlign: 'middle', marginTop: '1px' }} /></a>
                        </section>
                        <section className="links">
                            <a className="aLink" href="/">Home</a>
                            <a className="aLink" href="/services">Services</a>
                            <a className="aLink" href="/support">Support</a>
                            <a className="aLink" href="/privacy-policy">Privacy Policy</a>
                            <a className="aLink" href="/terms-of-service/">Terms Of Service</a>
                            <a className="aLink" href="/license/">License</a>
                            {/*<a className="aLink" href="/faq">FAQ</a>*/}
                            <a className="aLink" href="/#contact">Contact Us</a>
                            {/* Conditional rendering for login/logout */}
                            {user ? (
                                // <>
                                //     <button onClick={handleNavigate} className="logout-btn">Cabinet</button>
                                //     <button onClick={handleLogout} className="logout-btn">Logout</button>
                                // </>
                                <>
                                    <a
                                        onClick={handleNavigate}
                                        className="aLink"
                                        role="button"
                                        href="#"
                                    >Cabinet
                                    </a>
                                    <a
                                        onClick={handleLogout}
                                        className="aLink"
                                        role="button"
                                        href="#"
                                    >Logout
                                    </a>
                                </>
                            ) : (
                                <a className="aLink" href="/login">Login</a>
                            )}
                            {/*<a className="aLink" href="/login">Login</a>*/}
                        </section>
                    </div>
                    <div className="border-shape"></div>
                    <div className="copyright">
                        © 2025 SmartBirdSolution - All rights reserved
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default FooterSection;
