import React from "react";
import "./ServicesPage.css";
import Footer from "../pageComponents/headerFooter/Footer";
import Header from "../pageComponents/headerFooter/Header";
import BackHomeBtn from "../pageComponents/backHomeBtn/backHomeBtn";

const tiers = ["Studio", "1-2 Bedroom", "3-5 Bedroom", "5+ Bedroom"];
// const features = [
//     { feature: "System installation", access: "test" },
// ];
const features = [
    {
        id: 1,
        name: "System installation",
        studioPrice: "$415",
        oneTwoBedroomPrice: "$675",
        threeFifeBedroomPrice: "$855",
        FifePlusBedroomPrice: "TBD"
    },
];

const partsTiers = ["All Users", "Premium Users"];
const parts = [
    {name: "System installation", access: [true, true]},
    {name: "Device configuration", access: [true, true]},
    {name: "Automations & scripts", access: [true, true]},
    {name: "Domain", access: [true, true]},
    {name: "Basic Security", access: [true, true]},
    {name: "Alerts & notifications", access: [true, true]},
    {name: "Modes", access: [true, true]},
    {name: "Smart Thermostat Integration", access: [true, true]},
    {name: "Smart Door Lock", access: [true, true]},
    {name: "Basic Dashboard", access: [true, true]},
    {name: "Premium Dashboard", access: [false, true]},
    {name: "Residents", access: [false, true]},
    {name: "Cinema mode", access: [false, true]}
];

const services = [
    {
        id: 1,
        name: "Room Light",
        price: "$150",
        description: "Usually its can be Kitchen, Dining room, Living room",
        additional: "Optional can add curtain, TV and more devices"
    },
    {
        id: 2,
        name: "Bedroom Light",
        price: "$210",
        description: "Room with different logic on day and night",
        additional: ""
    },
    {
        id: 3,
        name: "Bathroom Light",
        price: "$110",
        description: "Room with turn on light only, turn off manually",
        additional: "+$50 fan configuration. Optional can be updated to basic room"
    },
    {
        id: 4,
        name: "Room Light with Speaker",
        price: "$230",
        description: "Room with an active speaker, will ask if you still here before turn off the light",
        additional: ""
    },
    {
        id: 4,
        name: "Bedroom Light with Speaker",
        price: "$265",
        description: "Bedroom with an active speaker, will ask if you still here before turn off the light with different behavior at night",
        additional: ""
    },
    {
        id: 5,
        name: "Vents",
        price: "$230",
        description: "Vents will actively open and close based on the temperature in the room",
        additional: "Dashboard can be updated"
    },
    {
        id: 6,
        name: "Bathroom Fan",
        price: "$100",
        description: "When humidity reach limit, fan is working till humidity is back to normal",
        additional: ""
    },
    {
        id: 7,
        name: "Service Room Light",
        price: "$20",
        description: "Usually its can be closet, entrance, other small rooms",
        additional: ""
    },
    {
        id: 8,
        name: "Cameras",
        price: "TBD",
        description: "Camera control installation",
        additional: "Dashboard can be updated"
    },
    {
        id: 9,
        name: "Custom Automation",
        price: "TBD",
        description: "Can be added custom automation as addition",
        additional: ""
    },
    {
        id: 10,
        name: "Customize Functionality",
        price: "TBD",
        description: "Can be added custom functionality as addition",
        additional: "Vehicle custom integration, pet feeder, coffee machine"
    },
    {
        id: 11,
        name: "Printer Integration",
        price: "$10",
        description: "Display printer's state, number of pages available",
        additional: ""
    },
    {
        id: 12,
        name: "Dashboard Additional Components",
        price: "TBD",
        description: "Some custom components client specific to be display on smart dashboard",
        additional: ""
    },
    {
        id: 13,
        name: "Dashboard Section Component",
        price: "$20-50",
        description: "By default you will receive some sections with build but if you would like to add more, use this option",
        additional: ""
    },
    {
        id: 14,
        name: "Smart Button",
        price: "$10",
        description: "Additional button to call any available action(light,fan,music,etc...)",
        additional: ""
    },
    {
        id: 14,
        name: "Smart Device Installation",
        price: "TBD",
        description: "If you decided to update your home with new devices(sensor,thermostat,button,etc...)",
        additional: ""
    },
    {id: 15, name: "NFC", price: "$1", description: "NFC tag can be added with custom automation", additional: ""}
];

const ServicesPage = () => {
    return (
        <div className="services-page">
            <Header titleName="System & Installation"/>

            <section className="services-intro-page">
                <div className="intro-content">
                    <h2>Welcome</h2>
                    <p>Below, you will find information about setup and installation. Some details may not be up to date,
                        so please <a href="/#contact"> contact us</a>  if you have any questions. Additionally,
                        visit our <a href="/support">support</a> page for more resources that may assist you in building your project.</p>

                    <h2>Prices:</h2>
                    <table className="service-table">
                        <thead>
                        <tr>
                            {/*<th> </th>*/}
                            {tiers.map((tier, index) => (
                                <th key={index}>{tier}</th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {/*{features.map(features => (*/}
                        {/*    <tr>*/}
                        {/*        <td><s>$850</s></td>*/}
                        {/*        <td><s>$1230</s></td>*/}
                        {/*        <td><s>$2350</s></td>*/}
                        {/*        <td>TBD</td>*/}
                        {/*    </tr>*/}
                        {/*))}*/}
                        {features.map(features => (
                            <tr key={features.id}>
                                <td>{features.studioPrice}</td>
                                <td>{features.oneTwoBedroomPrice}</td>
                                <td>{features.threeFifeBedroomPrice}</td>
                                <td>{features.FifePlusBedroomPrice}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                    <p></p>
                    <h2>Components:</h2>
                    <table className="support-table">
                        <thead>
                        <tr>
                            <th>Feature</th>
                            {partsTiers.map((partsTiers, index) => (
                                <th key={index}>{partsTiers}</th>
                            ))}
                        </tr>
                        </thead>
                        <tbody>
                        {parts.map((item, index) => (
                            <tr key={index}>
                                <td>{item.name}</td>
                                {item.access.map((hasAccess, tierIndex) => (
                                    <td key={tierIndex}>{hasAccess ? "✅" : "❌"}</td>
                                ))}
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>

                <div className="services-intro-page">
                    <h2>Additional Offers</h2>
                    <p>Explore our range of smart home services designed to fit your needs.</p>
                </div>
                <div className="services-table-container">
                    <table className="services-table">
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Price</th>
                            <th>Description</th>
                            <th>Additional</th>
                        </tr>
                        </thead>
                        <tbody>
                        {services.map(service => (
                            <tr key={service.id}>
                                <td>{service.name}</td>
                                <td>{service.price}</td>
                                <td>{service.description}</td>
                                <td>{service.additional}</td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </section>
            {/*<section className="services-list">*/}

            {/*</section>*/}

            <BackHomeBtn/>
            <Footer/>
        </div>
    );
};

export default ServicesPage;
